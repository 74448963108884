.chat-details {
  width: 60%;
}

.unread .name,
.unread .last-message {
  font-weight: bold;
}

.message-item {
  position: relative;
  max-width: 75%;
  word-break: break-word;
  background-color: var(--bs-white);
}

.message-item:before {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-style: solid;
  top: 5px;
  border-width: 0 10px 10px 0;
  border-color: transparent var(--bs-white) transparent transparent;
  right: 100%;
}

.message-item .body {
  white-space: pre-wrap;
}

.message-item.self {
  background: #dcf8c6;
}

.message-item.self:before {
  content: "";
  border-width: 10px 10px 0 0;
  border-color: #dcf8c6 transparent transparent transparent;
  transform: rotate(0deg);
  right: initial;
  left: 100%;
}

[data-bs-theme='dark'] .message-item.self {
  background: #005c4b;
}

[data-bs-theme='dark'] .message-item.self:before {
  content: "";
  border-color: #005c4b transparent transparent transparent;
}

.message-item .number {
  color: #1f7aec !important;
}

.message-item .options {
  background-color: var(--bs-white);
  position: absolute;
  top: 0;
  right: -3px;
  opacity: 0;
  transition: all .2s ease-in-out;
  -moz-transition: all .2s ease-in-out;
  -webkit-transition: all .2s ease-in-out;
}
.message-item.self .options {
  background-color: #dcf8c6;
}

.message-item:hover .options {
  opacity: 1;
  right: 0;
}
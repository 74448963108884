.swal2-title {
  font-family: "Inter", sans-serif !important;
}

.swal2-progress-steps .swal2-progress-step,
.swal2-progress-steps .swal2-progress-step.swal2-active-progress-step,
.swal2-progress-steps .swal2-progress-step-line {
  background: var(--bs-primary) !important;
}

.swal2-progress-steps
  .swal2-progress-step.swal2-active-progress-step
  ~ .swal2-progress-step,
.swal2-progress-steps
  .swal2-progress-step.swal2-active-progress-step
  ~ .swal2-progress-step-line {
  background: var(--bs-primary-border-subtle) !important;
  opacity: 0.5 !important;
}

.swal2-radio {
  accent-color: var(--bs-primary) !important;
}

.swal2-footer {
  border-color: var(--bs-gray-200) !important;
}

.swal2-loader {
  border-color: var(--bs-primary) transparent var(--bs-primary) transparent !important;
}

@import "../node_modules/bootstrap/scss/functions";

$enable-negative-margins: true;
$zindex-dropdown: 1025;

$blue:    #227ebe;
$indigo:  #6610f2;
$purple:  #6f42c1;

$primary:       $indigo;
$pure-white:    #FFF;
$pure-black:    #000;

@import "../node_modules/bootstrap/scss/variables";
@import "../node_modules/bootstrap/scss/variables-dark";

$light:         $gray-100;
$dark:          $gray-900;
$gray:          $gray-500;

$custom-colors: (
  "primary":    $primary,
  "indigo":     $indigo,
  "yellow":     $yellow,
  "red":        $red,
  "green":      $green,
  "cyan":       $cyan,
  "light":      $light,
  "dark":       $dark,
  "gray":       $gray,
  "blue":       $blue,
  "purple":     $purple,
  "pink":       $pink,
  "orange":     $orange,
  "pure-white": $pure-white,
  "pure-black": $pure-black,
);

$all-colors: map-merge-multiple($grays, $blues, $indigos, $purples, $pinks, $reds, $oranges, $yellows, $greens, $teals, $cyans);

$theme-colors: map-merge($theme-colors, $all-colors);
$theme-colors: map-merge($theme-colors, $custom-colors);

$input-placeholder-color: var(--bs-gray-500);


/* import bootstrap to set changes */
@import "../node_modules/bootstrap/scss/maps";

@import "../node_modules/bootstrap/scss/mixins";

@import "../node_modules/bootstrap/scss/utilities";

@import "../node_modules/bootstrap/scss/root";
@import "../node_modules/bootstrap/scss/reboot";
@import "../node_modules/bootstrap/scss/type";
@import "../node_modules/bootstrap/scss/images";
@import "../node_modules/bootstrap/scss/containers";
@import "../node_modules/bootstrap/scss/grid";
@import "../node_modules/bootstrap/scss/tables";
@import "../node_modules/bootstrap/scss/forms";
@import "../node_modules/bootstrap/scss/buttons";
@import "../node_modules/bootstrap/scss/transitions";
@import "../node_modules/bootstrap/scss/dropdown";
@import "../node_modules/bootstrap/scss/button-group";
@import "../node_modules/bootstrap/scss/nav";
@import "../node_modules/bootstrap/scss/navbar";
@import "../node_modules/bootstrap/scss/card";
@import "../node_modules/bootstrap/scss/accordion";
@import "../node_modules/bootstrap/scss/breadcrumb";
@import "../node_modules/bootstrap/scss/pagination";
@import "../node_modules/bootstrap/scss/badge";
@import "../node_modules/bootstrap/scss/alert";
@import "../node_modules/bootstrap/scss/progress";
@import "../node_modules/bootstrap/scss/list-group";
@import "../node_modules/bootstrap/scss/close";
@import "../node_modules/bootstrap/scss/toasts";
@import "../node_modules/bootstrap/scss/modal";
@import "../node_modules/bootstrap/scss/tooltip";
@import "../node_modules/bootstrap/scss/popover";
@import "../node_modules/bootstrap/scss/carousel";
@import "../node_modules/bootstrap/scss/spinners";
@import "../node_modules/bootstrap/scss/offcanvas";
@import "../node_modules/bootstrap/scss/placeholders";

@import "../node_modules/bootstrap/scss/helpers";

@import "../node_modules/bootstrap/scss/utilities/api";

$utilities: map-merge(
  $utilities, (
    "rounded": map-merge(
      map-get($utilities, "rounded"),
      ( responsive: true )
    ),
    "rounded-top": map-merge(
      map-get($utilities, "rounded-top"),
      ( responsive: true )
    ),
    "rounded-bottom": map-merge(
      map-get($utilities, "rounded-bottom"),
      ( responsive: true )
    ),
    "rounded-start": map-merge(
      map-get($utilities, "rounded-start"),
      ( responsive: true )
    ),
    "rounded-end": map-merge(
      map-get($utilities, "rounded-end"),
      ( responsive: true )
    )
  )
);

/**CUSTOM BORDER (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px, xxl: 1400px) **/
@media (min-width:576px){.border-sm{border:var(--bs-border-width) var(--bs-border-style) var(--bs-border-color)!important;}.border-sm-top{border-top:var(--bs-border-width) var(--bs-border-style) var(--bs-border-color)!important;}.border-sm-right{border-right:var(--bs-border-width) var(--bs-border-style) var(--bs-border-color)!important;}.border-sm-bottom{border-bottom:var(--bs-border-width) var(--bs-border-style) var(--bs-border-color)!important;}.border-sm-start{border-left:var(--bs-border-width) var(--bs-border-style) var(--bs-border-color)!important;}.border-sm-top-0{border-top:0!important;}.border-sm-end-0{border-right:0!important;}.border-sm-bottom-0{border-bottom:0!important;}.border-sm-start-0{border-left:0!important;}.border-sm-x{border-left:var(--bs-border-width) var(--bs-border-style) var(--bs-border-color)!important;border-right:var(--bs-border-width) var(--bs-border-style) var(--bs-border-color)!important;}.border-sm-y{border-top:var(--bs-border-width) var(--bs-border-style) var(--bs-border-color)!important;border-bottom:var(--bs-border-width) var(--bs-border-style) var(--bs-border-color)!important;}.border-sm-0{border:0!important;}.border-top-sm-0{border-top:0!important;}.border-end-sm-0{border-right:0!important;}.border-bottom-sm-0{border-bottom:0!important;}.border-start-sm-0{border-left:0!important;}}
@media (min-width:768px){.border-md{border:var(--bs-border-width) var(--bs-border-style) var(--bs-border-color)!important;}.border-md-top{border-top:var(--bs-border-width) var(--bs-border-style) var(--bs-border-color)!important;}.border-md-right{border-right:var(--bs-border-width) var(--bs-border-style) var(--bs-border-color)!important;}.border-md-bottom{border-bottom:var(--bs-border-width) var(--bs-border-style) var(--bs-border-color)!important;}.border-md-start{border-left:var(--bs-border-width) var(--bs-border-style) var(--bs-border-color)!important;}.border-md-top-0{border-top:0!important;}.border-md-end-0{border-right:0!important;}.border-md-bottom-0{border-bottom:0!important;}.border-md-start-0{border-left:0!important;}.border-md-x{border-left:var(--bs-border-width) var(--bs-border-style) var(--bs-border-color)!important;border-right:var(--bs-border-width) var(--bs-border-style) var(--bs-border-color)!important;}.border-md-y{border-top:var(--bs-border-width) var(--bs-border-style) var(--bs-border-color)!important;border-bottom:var(--bs-border-width) var(--bs-border-style) var(--bs-border-color)!important;}.border-md-0{border:0!important;}.border-top-md-0{border-top:0!important;}.border-end-md-0{border-right:0!important;}.border-bottom-md-0{border-bottom:0!important;}.border-start-md-0{border-left:0!important;}}
@media (min-width:992px){.border-lg{border:var(--bs-border-width) var(--bs-border-style) var(--bs-border-color)!important;}.border-lg-top{border-top:var(--bs-border-width) var(--bs-border-style) var(--bs-border-color)!important;}.border-lg-right{border-right:var(--bs-border-width) var(--bs-border-style) var(--bs-border-color)!important;}.border-lg-bottom{border-bottom:var(--bs-border-width) var(--bs-border-style) var(--bs-border-color)!important;}.border-lg-start{border-left:var(--bs-border-width) var(--bs-border-style) var(--bs-border-color)!important;}.border-lg-top-0{border-top:0!important;}.border-lg-end-0{border-right:0!important;}.border-lg-bottom-0{border-bottom:0!important;}.border-lg-start-0{border-left:0!important;}.border-lg-x{border-left:var(--bs-border-width) var(--bs-border-style) var(--bs-border-color)!important;border-right:var(--bs-border-width) var(--bs-border-style) var(--bs-border-color)!important;}.border-lg-y{border-top:var(--bs-border-width) var(--bs-border-style) var(--bs-border-color)!important;border-bottom:var(--bs-border-width) var(--bs-border-style) var(--bs-border-color)!important;}.border-lg-0{border:0!important;}.border-top-lg-0{border-top:0!important;}.border-end-lg-0{border-right:0!important;}.border-bottom-lg-0{border-bottom:0!important;}.border-start-lg-0{border-left:0!important;}}
@media (min-width:1200px){.border-xl{border:var(--bs-border-width) var(--bs-border-style) var(--bs-border-color)!important;}.border-xl-top{border-top:var(--bs-border-width) var(--bs-border-style) var(--bs-border-color)!important;}.border-xl-right{border-right:var(--bs-border-width) var(--bs-border-style) var(--bs-border-color)!important;}.border-xl-bottom{border-bottom:var(--bs-border-width) var(--bs-border-style) var(--bs-border-color)!important;}.border-xl-start{border-left:var(--bs-border-width) var(--bs-border-style) var(--bs-border-color)!important;}.border-xl-top-0{border-top:0!important;}.border-xl-end-0{border-right:0!important;}.border-xl-bottom-0{border-bottom:0!important;}.border-xl-start-0{border-left:0!important;}.border-xl-x{border-left:var(--bs-border-width) var(--bs-border-style) var(--bs-border-color)!important;border-right:var(--bs-border-width) var(--bs-border-style) var(--bs-border-color)!important;}.border-xl-y{border-top:var(--bs-border-width) var(--bs-border-style) var(--bs-border-color)!important;border-bottom:var(--bs-border-width) var(--bs-border-style) var(--bs-border-color)!important;}.border-xl-0{border:0!important;}.border-top-xl-0{border-top:0!important;}.border-end-xl-0{border-right:0!important;}.border-bottom-xl-0{border-bottom:0!important;}.border-start-xl-0{border-left:0!important;}}
@media (min-width:1400px){.border-xxl{border:var(--bs-border-width) var(--bs-border-style) var(--bs-border-color)!important;}.border-xxl-top{border-top:var(--bs-border-width) var(--bs-border-style) var(--bs-border-color)!important;}.border-xxl-right{border-right:var(--bs-border-width) var(--bs-border-style) var(--bs-border-color)!important;}.border-xxl-bottom{border-bottom:var(--bs-border-width) var(--bs-border-style) var(--bs-border-color)!important;}.border-xxl-start{border-left:var(--bs-border-width) var(--bs-border-style) var(--bs-border-color)!important;}.border-xxl-top-0{border-top:0!important;}.border-xxl-end-0{border-right:0!important;}.border-xxl-bottom-0{border-bottom:0!important;}.border-xxl-start-0{border-left:0!important;}.border-xxl-x{border-left:var(--bs-border-width) var(--bs-border-style) var(--bs-border-color)!important;border-right:var(--bs-border-width) var(--bs-border-style) var(--bs-border-color)!important;}.border-xxl-y{border-top:var(--bs-border-width) var(--bs-border-style) var(--bs-border-color)!important;border-bottom:var(--bs-border-width) var(--bs-border-style) var(--bs-border-color)!important;}.border-xxl-0{border:0!important;}.border-top-xxl-0{border-top:0!important;}.border-end-xxl-0{border-right:0!important;}.border-bottom-xxl-0{border-bottom:0!important;}.border-start-xxl-0{border-left:0!important;}}
 
@import "styles/styles.scss";
:root {
  --fc-border-color: var(--bs-border-color);
}
[data-bs-theme='dark'] {
  --fc-border-color: var(--bs-border-color);
  /* --bs-primary: var(--bs-indigo-600); */
}
.fc-toolbar-title:first-letter {
  text-transform: uppercase;
}

.fc .fc-button {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 30px;
  font-size: 11px;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: .05rem;

  &:focus {
    outline: 0;
  }
}

.fc .fc-button-primary {
  background-color: var(--bs-white);
  border-color: var(--bs-primary);
  color: var(--bs-primary);

  &:hover {
    background-color: var(--bs-primary);
    color: var(--bs-pure-white);
    border-color: var(--bs-primary)
  }
}

.fc .fc-button .fc-icon {
  font-size: unset;
}
.fc .fc-button-primary:not(:disabled).fc-button-active {
  background-color: var(--bs-primary);
  color: var(--bs-pure-white);
  border-color: var(--bs-primary);
}
.fc .fc-button-primary:not(:disabled):active {
  background-color: var(--bs-primary);
  color: var(--bs-pure-white);
  border-color: var(--bs-primary);
}
.fc .fc-button-primary:disabled {
  background-color: var(--bs-gray-500);
  border-color: var(--bs-gray-500);
}
.fc .fc-view-harness {
  background: var(--bs-white);
  border-radius: var(--bs-border-radius);
  box-shadow: 0 15px 30px -5px var(--bs-gray-200);
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
  max-width: 90%;
  padding: .5rem
}

@media (max-width:575.98px) {
  .fc .fc-view-harness {
    max-width: calc(100vw - 30px);
  }
}

@media (min-width:1780px) {
  .fc .fc-view-harness {
    max-width: 1600px;
  }
}

.fc-direction-ltr .fc-daygrid-event.fc-event-end {
  cursor: pointer;
}
.fc .fc-view-harness {
  max-width: 100% !important;
}
.fc a {
  text-decoration: none;
}
.fc .fc-daygrid-day.fc-day-today {
  background-color: var(--bs-indigo-100);
}
.fc .fc-timegrid-col.fc-day-today {
  background-color: var(--bs-gray-100);
}
.fc .fc-today-button:disabled {
  border-color: var(--bs-primary) !important;
  background-color: var(--bs-primary) !important;
  opacity: 1;
}
.fc-col-header-cell-cushion {
  font-size: small;
}
.fc .fc-view-harness-active>.fc-view {
  inset: 1rem;
}
.fc .fc-scrollgrid-section-sticky>* {
  background-color: var(--bs-body-bg);
}
.fc-timegrid-event-harness-inset .fc-timegrid-event,
.fc-timegrid-event.fc-event-mirror,
.fc-timegrid-more-link {
  box-shadow: none;
}
.fc-toolbar.fc-header-toolbar {
  margin-top: -3rem;
  margin-bottom: 3rem;
  margin-left: calc(-50vw + 800px - 5vw - 15px);
  margin-right: calc(-50vw + 800px - 5vw - 15px);
  padding-left: calc(50vw - 800px + 5vw + 15px);
  padding-right: calc(50vw - 800px + 5vw + 15px);
  padding-top: 3rem;
  padding-bottom: 1.5rem;
}
@media(max-width: 1600px) {
  .fc-toolbar.fc-header-toolbar {
    margin-left: calc(-5vw - 15px);
    margin-right: calc(-5vw - 15px);
    padding-left: calc(5vw + 15px);
    padding-right: calc(5vw + 15px);
  }
}
@media(max-width: 992px) {
  .fc-toolbar.fc-header-toolbar {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .fc-toolbar.fc-header-toolbar .fc-toolbar-chunk:nth-child(1) {
    order: 2;
    flex: 0 0 auto;
    padding-top: 1rem;
  }
  .fc-toolbar.fc-header-toolbar .fc-toolbar-chunk:nth-child(2) {
    order: 1;
    flex: 0 0 100%;
    text-align: center;
  }
  .fc-toolbar.fc-header-toolbar .fc-toolbar-chunk:nth-child(3) {
    order: 3;
    flex: 0 0 auto;
    padding-top: 1rem;
  }
}
@media(max-width: 540px) {
  .fc-toolbar.fc-header-toolbar {
    justify-content: center;
    flex-direction: column;
  }
}
/**BASE**/
html {
    height: -webkit-fill-available;
}
body {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    min-height: -webkit-fill-available;
}
body>.tooltip {
    --tooltip-bg: var(--bs-black);
    position: absolute;
}
#site {
    display: flex;
    flex: 1 1 auto;
    width: 100%;
    position: relative;
    transition: margin .2s ease-in-out;
    -moz-transition: margin .2s ease-in-out;
    -webkit-transition: margin .2s ease-in-out;
    flex-direction: column;
}
#content {
    flex-grow: 1;
}
.padding-header {
    padding-top: 140px;
}

/* header */
#header-site {
    position: fixed;
    top: 0;
    height: auto;
    flex-shrink: 0;
    z-index: 1050;
    width: 100%;
    /* overflow: hidden; */
    transition: margin .5s ease-in-out;
    -moz-transition: margin .5s ease-in-out;
    -webkit-transition: margin .5s ease-in-out;
}
#header-site .colors {
    margin-top: -1rem;
}
#header-site .colors>.col {
    height: 5px;
}
#header-site .bg-header {
    margin: 1.5rem -1.5rem;
    padding: 0 1.5rem;
    border-radius: var(--bs-border-radius-xl);
    background-color: rgba(var(--bs-body-bg-rgb), 0);
    transition: background .5s ease-in-out, margin .5s ease-in-out;
    -moz-transition: background .5s ease-in-out, margin .5s ease-in-out;
    -webkit-transition: background .5s ease-in-out, margin .5s ease-in-out;
}
#header-site.scrolled .bg-header,
#header-site.bg-on .bg-header {
    background-color: rgba(var(--bs-white-rgb), .7);
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
}
@media only screen and (max-width: 576px) {
    #header-site.scrolled .bg-header {
        margin-top: 0;
    }
}
#header-site .logo {
    display: inline-block;
    width: 200px;
    max-width: 100%;
}
#header-site .logo h1 {
    margin-bottom: 0;
}
#header-site .abre-menu {
    display: none;
}
#header-site #menu>ul {
    margin: 0;
    padding: 0;
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    list-style: none;
}
#header-site #menu>ul li {
    padding: 0;
}
#header-site #menu a {
    display: block;
    text-align: center;
    border-radius: var(--bs-border-radius);
    font-size: 16px;
    padding: .5rem 1rem;
    text-decoration: none;
    text-wrap: nowrap;
}
#header-site #menu a:not(.btn) {
    color: var(--bs-body-color);
}
#header-site #menu a:not(.btn):hover {
    background: rgba(var(--bs-white-rgb), .1);
}
#header-site #menu>ul li.active a:not(.btn) {
    background: rgba(var(--bs-white-rgb), .1);
}

/* footer  */
#footer-site {
    flex-shrink: 0;
    background-color: rgba(var(--bs-white-rgb), .1);
}
#footer-site .logo {
    max-width: 100%;
}

/* bannder */
#banner {
    background: url('/src/assets/bg-pattern.png') center top no-repeat;
    background-size: contain;
    position: relative;
    z-index: 1;
}
.hand-circle {
    position: relative;
    display: inline-block;
}
.hand-circle:after {
    border: .15em solid var(--bs-blue-400);
    border-top: none;
    border-radius: 1.5em 1em 2em 1.5em;
    bottom: -.2em;
    content: '';
    left: -.2em;
    position: absolute;
    right: -.2em;
    top: -.1em;
}
.hand-circle:before {
    border-top: .15em solid var(--bs-blue-400);
    border-radius: .5em;
    content: '';
    height: 1em;
    left: 0;
    position: absolute;
    top: -.2em;
    transform: rotate(-10deg);
    width: 2em;
}
#site .bg-gradient-login {
    background-size: initial;
    background-position: 0 180%;
    background-attachment: fixed;
}
.bg-gradient-print {
    background-image: linear-gradient(180deg, var(--bs-gray-100), var(--bs-gray-200) 45%);
}
.bg-rounded {
    background-image: url('/src/assets/bg-rounded.png');
    background-position: 50% 100%;
    background-repeat: no-repeat;
    background-size: contain;
    padding-top: 140px;
    margin-top: -70px;
    overflow: hidden;
}
.img-print-1 {
    transform: rotate(-9.4deg);
}
.img-print-2 {
    transform: rotate(3deg);
}
.img-print-3 {
    transform: rotate(-8.5deg);
}
.feature-image {
    position: relative;
    overflow: hidden;
}
.feature-image:before {
    content: "";
    background-image: linear-gradient(260deg, rgba(var(--bs-body-bg-rgb), 100) 10%, rgba(var(--bs-body-bg-rgb), 0));
    width: 100%;
    position: absolute;
    z-index: 1;
    height: 100%;
}
.feature-image>img {
    height: 400px;
    object-fit: cover;
    object-position: left top;
}
.feature-image-cursor {
    z-index: 2;
    top: 50%;
}
.feature-image-cursor img {
    transform: rotate(-9.4deg);
    height: 95px;
}
.funcionality-image {
    position: relative;
    overflow: hidden;
}
.funcionality-image img {
    height: 400px;
    object-fit: cover;
    object-position: center center;
}
.mobile-indicator {
    position: relative;
}
.mobile-indicator:not(.text-bg-primary):hover {
    --bs-bg-opacity: .5;
}
.mobile-indicator:before {
    content: "";
    position: absolute;
    width: var(--bs-gutter-x);
    top: 50%;
    border: 1px dashed var(--bs-dark);
    left: 100%;
}
.mobile-indicator.right:before {
    left: initial;
    right: 100%;
}

@media only screen and (max-width: 991px) {
    .mobile-indicator:before {
        display: none;
    }
}

h1:before {
    left: -36px;
    border-right-width: 18px;
    border-left-color: transparent;
}
h1:after {
    right: -36px;
    border-left-width: 18px;
    border-right-color: transparent;
}
h1 span:before,
h1 span:after {
    content: "";
    position: absolute;
    border-style: solid;
    border-color: #A7CECC transparent transparent transparent;
    top: -6px;
    transform: rotate(180deg);
}
h1 span:before {
    left: 0;
    border-width: 6px 0 0 6px;
}
h1 span:after {
    right: 0;
    border-width: 6px 6px 0 0;
}

/* form */
.form-floating>label {
    max-width: 100%;
}


.bg-cta {
    background: url('/src/assets/bg-login.png'), linear-gradient(45deg, var(--bs-indigo-600) 0%, var(--bs-purple-400) 100%);
    background-repeat: no-repeat;
    background-position: left bottom;
    background-size: cover;
    /* overflow: hidden; */
    position: relative;
}
.bg-cta>* {
    position: relative;
    z-index: 1;
}
.bg-cta:before {
    content: "";
    border-radius: var(--bs-border-radius-xl);
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-image: linear-gradient(45deg, var(--bs-indigo-600) 0%, var(--bs-purple-400) 100%);
    opacity: .8;
}

.class-footer-purple {
    background: var(--bs-purple-600);
    overflow: hidden;
    position: relative;
    border-top: 2px solid var(--bs-purple-600);
    box-shadow: inset 0px 1px 0px var(--bs-white);
}
.class-footer-purple>* {
    position: relative;
    z-index: 1;
}
.class-footer-purple:before {
    content: "";
    border-radius: 50%;
    width: 50vw;
    height: 100%;
    left: 50%;
    transform: translateX(-50%);
    top: 50%;
    filter: blur(120px);
    position: absolute;
    background: var(--bs-purple-500);
    opacity: .8;
    z-index: 0;
}


@media only screen and (max-width: 768px) {
    #header-site a.abre-menu {
        display: flex;
        text-align: center;
        text-decoration: none;
        align-items: center;
    }

    #header-site a.abre-menu i:before {
        content: "\ebae";
    }

    .left-menu #header-site a.abre-menu i:before {
        content: "\e9b8";
    }

    #header-site #menu {
        position: absolute;
        top: calc(100% - 200px);
        opacity: 0;
        margin-top: 0;
        padding: .25rem 0;
        visibility: hidden;
        left: 1rem;
        right: 1rem;
        z-index: 10;
        max-height: calc(100vh - 100%);
        overflow-x: hidden;
        overflow-y: auto;
        border-radius: var(--bs-border-radius-xl);
        background-color: rgba(var(--bs-white-rgb), 1);
        transition: opacity .3s ease-in-out, top .1s ease-in-out;
        -moz-transition: opacity .3s ease-in-out, top .1s ease-in-out;
        -webkit-transition: opacity .3s ease-in-out, top .1s ease-in-out;
        box-shadow: 0 1rem 3rem rgba(var(--black-rgb), 0.175);
        border: 1px solid var(--bs-border-color);
        border-top: none;
        box-shadow: var(--bs-box-shadow);
    }

    #header-site #menu ul {
        width: 100%;
    }

    .left-menu #header-site #menu {
        opacity: 1;
        visibility: visible;
        top: 100%;
    }

    .left-menu #header-site .abre-menu .fa-bars::before {
        content: "\f00d";
    }

    .left-menu #root .menu-overlay {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(var(--bs-white-rgb), 0.2);
        z-index: 10;
        backdrop-filter: blur(3px);
        -webkit-backdrop-filter: blur(3px);
    }

    #header-site #menu ul {
        flex-direction: column;
    }

    #header-site #menu ul li {
        display: block;
        padding: .25rem .5rem;
    }

    #header-site #menu li a:not(.btn) {
        width: auto;
        height: auto;
        border-radius: 0;
        font-size: calc(1rem + .8vw);
        padding: .5rem;
        display: flex;
        align-items: center;
        font-size: initial;
        line-height: initial;
        text-decoration: none;
        border-radius: var(--bs-border-radius);
    }

    #header-site #menu li a:not(.btn):hover {
        background-color: rgba(var(--black-rgb), 0.075);
    }

    #header-site #menu li a:not(.btn) span {
        display: block;
        text-align: center;
        width: 100%;
    }

    #header-site #menu ul li.active a {
        color: var(--bs-white);
        background: var(--bs-indigo);
    }
}
@media only screen and (min-width: 576px) and (max-width: 1200px) {
    .container {
        max-width: 90% !important;
    }
}
:root{
    --xy-controls-button-background-color:var(--bs-white);
    --xy-controls-button-background-color-hover:var(--bs-gray-200);
    --xy-controls-button-border-color-props:var(--bs-border-color);
    --xy-controls-button-color-props:var(--bs-body-color);
    --xy-attribution-background-color:transparent;
    --xy-minimap-mask-background-color-props:rgba(240,240,240,.6);
    --xy-minimap-node-background-color-props:var(--bs-gray-400);
    --xy-minimap-background-color-props:var(--bs-white)
}
[data-bs-theme='dark'] {
    --xy-controls-button-background-color:var(--bs-white);
    --xy-controls-button-background-color-hover:var(--bs-gray-300);
    --xy-controls-button-border-color-props:var(--bs-border-color);
    --xy-controls-button-color-props:var(--bs-body-color);
    --xy-attribution-background-color:transparent;
    --xy-minimap-mask-background-color-props:rgba(28, 28, 28, 0.6);
    --xy-minimap-node-background-color-props:var(--bs-gray-400);
    --xy-minimap-background-color-props:var(--bs-white)
}
.flow-component{
    user-select: none;
    cursor: grab;
}
.flow-component:not(.disabled):hover{
    border-color: var(--primary);
    box-shadow: var(--bs-box-shadow-sm);
}
.message-node,
.wait-time-node,
.loop-node{
    width: 250px;
}
.react-flow__handle{
    width: 10px;
    height: 10px;
    background-color: var(--bs-white);
    border: 1px solid var(--bs-gray-700);
}
.btn.btn-edge{
    font-size: 10px;
    width: 20px;
    height: 20px;
    padding: 0;
    --bs-btn-bg:var(--bs-white);
    --bs-btn-color:var(--bs-body-color);
    --bs-btn-border-color:var(--bs-gray-700);
    --bs-btn-hover-color:var(--bs-danger);
    --bs-btn-hover-border-color:var(--bs-danger);
    --bs-btn-hover-bg: var(--bs-white);
    --bs-btn-active-color:var(--bs-danger);
    --bs-btn-active-border-color:var(--bs-danger);
    --bs-btn-active-bg: var(--bs-white);
}
.message-body{
  white-space: pre-wrap;
}

.mini .react-flow__attribution{
    display: none;
}
.mini .react-flow__renderer{
    opacity: 0;
    pointer-events: none;
}
.mini .react-flow__minimap{
    bottom: initial;
    top: 50%;
    left: 50%;
    right: initial;
    transform: translate(-50%, -50%);
    margin: 0;
}
#kanban {
  display: flex;
  flex-direction: column;
  height: 90vh;
}

.gu-mirror {
  opacity: .8;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
  filter: alpha(opacity=80)
}

.gu-transit {
  opacity: .2;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
  filter: alpha(opacity=20)
}

.kanban-parent {
  user-select: none;
  position: relative;
  width: auto;
  overflow-y: hidden;
  overflow-x: auto;
  padding: 0 0 1rem 5vw;
  margin-bottom: -2rem;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
}

.kanban-container {
  display: flex;
  width: auto;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 10px;
  height: 100%;
}

.kanban-container>div:last-child {
  padding-right: 5vw;
}

.kanban-board {
  position: relative;
  background: var(--bs-gray-200);
  transition: all .3s cubic-bezier(.23, 1, .32, 1);
  border-radius: .5rem;
  width: 300px;
  min-width: 300px;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.kanban-board .kanban-board-header .dropdown {
  visibility: hidden;
}

.kanban-board:hover .kanban-board-header .dropdown {
  visibility: visible;
}

.kanban-board.disabled-board {
  opacity: .3
}

.kanban-board.is-moving.gu-mirror {
  transform: rotate(3deg)
}

.kanban-board.is-moving.gu-mirror .kanban-drag {
  overflow: hidden;
  padding-right: 50px
}

.kanban-board-header {
  top: 0 !important;
  padding: 1rem;
  background: var(--bs-gray-200);
  cursor: default !important;
}

.kanban-board .drag-handle {
  cursor: move;
}

.kanban-board .kanban-drag {
  min-height: 200px;
  padding: 0 1rem 1rem 1rem;
  height: 100%;
  overflow-y: auto;
}

.kanban-board .bottom-button {
  background: var(--bs-gray-200);
  text-align: center;
}

.kanban-board .bottom-button a {
  text-decoration: none;
  color: var(--bs-body-color);
}

.kanban-board .bottom-button a:hover {
  background: var(--bs-gray-300);
}

.kanban-board .add-column {
  visibility: hidden;
  opacity: 0;
  transition: opacity .3s ease;
  z-index: 2;
}

.kanban-board .add-column .btn:not(:hover) {
  background-color: var(--bs-light);
}

.kanban-board:hover .add-column {
  visibility: visible;
  opacity: 1;
}

.kanban-item {
  background: var(--bs-body-bg);
  padding: .75rem;
  margin-bottom: .75rem;
  transition: all .3s cubic-bezier(.23, 1, .32, 1);
  cursor: default !important;
}

.kanban-item .text-limit {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
}

.dragOverlay>.kanban-item {
  transform: rotate(-3deg);
  box-shadow: var(--bs-box-shadow) !important;
}

.dragOverlay>.kanban-board {
  transform: rotate(-2deg) !important;
  box-shadow: var(--bs-box-shadow) !important;
}

.kanban-item .dropdown {
  visibility: hidden;
}

.kanban-item:hover .dropdown {
  visibility: visible;
}

@media (max-width:640px) {

  .kanban-item .dropdown,
  .kanban-board .kanban-board-header .dropdown {
    visibility: visible;
  }
}

.kanban-item .border-icons {
  --bs-border-opacity: .2;
}

.kanban-item img.w-100 {
  border-radius: .5rem
}

.kanban-item:hover {
  cursor: move
}

.kanban-item:last-child {
  margin: 0
}

.kanban-item.is-moving.gu-mirror {
  transform: rotate(3deg);
  height: auto !important
}

.gu-mirror {
  position: fixed !important;
  margin: 0 !important;
  z-index: 9999 !important
}

.gu-hide {
  display: none !important
}

.gu-unselectable {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important
}

.gu-transit {
  box-shadow: inset 0 0 2px #fefefed1;
  -webkit-backdrop-filter: saturate(200%) blur(30px);
  backdrop-filter: saturate(200%) blur(30px);
  background-color: hsla(0, 0%, 100%, .8) !important;
  box-shadow: inset 0 0 1px 1px hsla(0, 0%, 100%, .9), 0 20px 27px 0 rgba(var(--bs-black-rgb), .05) !important
}

.drag_handler {
  background: var(--bs-white);
  border-radius: 50%;
  width: 24px;
  height: 24px;
  position: relative;
  float: left;
  top: -3px;
  margin-right: 4px
}

.drag_handler:hover {
  cursor: move
}

.drag_handler_icon {
  position: relative;
  display: block;
  background: #000;
  width: 24px;
  height: 2px;
  top: 12px;
  transition: .5s ease-in-out
}

.drag_handler_icon:after,
.drag_handler_icon:before {
  background: #000;
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  transition: .5s ease-in-out
}

.drag_handler_icon:before {
  top: 6px
}

.drag_handler_icon:after {
  bottom: 6px
}


.kanban-board .kanban-drag .add-flow{
  opacity: 0;
  transition: .2s ease-in-out;
  text-decoration: none;
  cursor: pointer!important;
}
.kanban-board:hover .kanban-drag .add-flow{
  opacity: 1;
}
